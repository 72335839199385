import useTranslation from 'next-translate/useTranslation';
import Badge from '@ui-v2/core/Badge/Badge';
import Box from '@ui-v2/core/Box/Box';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  onClickBadge: () => void;
}

const NoCheckedBagsBadge = ({ onClickBadge }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      id="no-checked-bags-tag-tooltip"
      text={t('airport_transfer_under_90_min', null, {
        default: 'Airport transfer duration is under 90 min',
      })}
    >
      <Box display={['none', 'block']}>
        <Badge
          as="button"
          icon="noLuggageIcon"
          onClick={onClickBadge}
          variant="warning"
        >
          {t('no_checked_bag_journey', null, {
            default: 'No checked-bag journey',
          })}
        </Badge>
      </Box>
      <Box display={['block', 'none']}>
        <Badge
          as="button"
          icon="noLuggageIcon"
          onClick={onClickBadge}
          variant="warning"
        />
      </Box>
    </Tooltip>
  );
};

export default NoCheckedBagsBadge;
