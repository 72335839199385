import useTranslation from 'next-translate/useTranslation';
import Icon from '@ui-v2/core/Icon/Icon';
import Notification from '@ui-v2/core/Notification/Notification';

export interface Props {
  stopDestination?: string;
  stopOrigin: string;
}

const ItineraryGroundTransitWarning = ({
  stopDestination,
  stopOrigin,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Notification variant="success">
      <Icon colour="icons.on.success" size={20} type="warningIcon" />

      {t('ground_transit_info', {
        origin: stopOrigin,
        destination: stopDestination,
      })}
    </Notification>
  );
};

export default ItineraryGroundTransitWarning;
