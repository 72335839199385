// Generated by Avo VERSION 122.33.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "L3q8U43rXKlk2ZsBpKS8";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "zBqd5TlNbQ0QGShrt9sz",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "KJttCUBS93c9o5mHkigS",
          "se": (new Date()).toISOString(),
          "so": "EVl2DnnJV",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "zBqd5TlNbQ0QGShrt9sz",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "KJttCUBS93c9o5mHkigS",
          "se": (new Date()).toISOString(),
          "so": "EVl2DnnJV",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "KJttCUBS93c9o5mHkigS",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export interface Sorts {
  type: string
  value: string
}

export interface Filters {
  type: string
  value: string
}

export interface Products {
  price: number
  currency: string
  productId: string
  position: number | null | undefined
  brand: string
  category: string | null | undefined
  name: string
  variant: VariantValueType | null | undefined
  quantity: number
}

export const SearchType = {
  'OUTBOUND': 'outbound',
  'INBOUND': 'inbound',
  'COMBINED': 'combined',
} as const;
export type SearchTypeType = typeof SearchType;
export type SearchTypeValueType = SearchTypeType[keyof SearchTypeType];

export const Variant = {
  'ONEWAY': 'oneway',
  'ROUNDTRIP': 'roundtrip',
} as const;
export type VariantType = typeof Variant;
export type VariantValueType = VariantType[keyof VariantType];

let RudderStack: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  RudderStackDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    RudderStack = RudderStackDestination;
    if (__AVO_ENV__ === 'prod') {
      RudderStack && RudderStack.make && RudderStack.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      RudderStack && RudderStack.make && RudderStack.make(__AVO_ENV__, null);
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for RudderStack. Head to destination settings in Avo to set a staging key.");
      RudderStack && RudderStack.make && RudderStack.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

function assertSorts(sorts: Sorts[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("AL2GN62Cpe", "sorts", sorts));
  if (sorts !== null && sorts !== undefined && sorts.forEach) {
    sorts.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("AL2GN62Cpe", "item at index " + index + " in sorts", value));
    });
  }
  return messages;
}

function assertFilters(filters: Filters[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("E0WG65Mqcj", "filters", filters));
  if (filters !== null && filters !== undefined && filters.forEach) {
    filters.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("E0WG65Mqcj", "item at index " + index + " in filters", value));
    });
  }
  return messages;
}

function assertProducts(products: Products[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("M79QMSmcZN", "products", products));
  if (products !== null && products !== undefined && products.forEach) {
    products.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("M79QMSmcZN", "item at index " + index + " in products", value));
    });
  }
  return messages;
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface PageviewProperties {
  customDestinationPageName_: string;
  path: string;
  pageTitle: string;
  pageName: string;
  referrer: string;
  search: string;
}
/**
 * pageview: Sent on every pageview
 *
 * @param properties the properties associatied with this event
 * @param properties.customDestinationPageName_: Name of the page or screen
 * @param properties.path: Page path
 * @param properties.pageTitle: Title
 * @param properties.pageName: Name of the page
 * @param properties.referrer: Referrer
 * @param properties.search: Search or query paramerers
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/NN5PqIMz-q}
 */
export function pageview(properties: PageviewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "JRme9k-Vcu", name: "path", value: properties.path});
  eventPropertiesArray.push({id: "SVn-nEhIZJ", name: "page_title", value: properties.pageTitle});
  eventPropertiesArray.push({id: "Vrh9UGrdOz", name: "page_name", value: properties.pageName});
  eventPropertiesArray.push({id: "z8SWgu6t6B", name: "referrer", value: properties.referrer});
  eventPropertiesArray.push({id: "ZmPKVzCxAh", name: "search", value: properties.search});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NN5PqIMz-q", "d8403e3a7e2d36af3de1da11bfec26bdb410be44540773a9307ba9d3f5003cae", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("pageview", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NN5PqIMz-q", "pageview", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    // destination RudderStack
    if (RudderStack.logPage) {
      RudderStack.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

export interface ProductListViewedProperties {
  products: Products[];
  totalAdult: number;
  totalChild: number;
  totalInfant: number;
  loadTime: string;
  searchType: SearchTypeValueType;
}
/**
 * Product List Viewed: Search result displayed for user
 *
 * @param properties the properties associatied with this event
 * @param properties.products: Products
 * @param properties.totalAdult: no description
 * @param properties.totalChild: no description
 * @param properties.totalInfant: no description
 * @param properties.loadTime: no description
 * @param properties.searchType: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/7822sLDIY8}
 */
export function productListViewed(properties: ProductListViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "M79QMSmcZN", name: "products", value: properties.products.map(function(value) { return {
      "price": value.price,
      "currency": value.currency,
      "product_id": value.productId,
      "position": value.position,
      "brand": value.brand,
      "category": value.category,
      "name": value.name,
      "variant": value.variant,
      "quantity": value.quantity,
    }
  })});
  eventPropertiesArray.push({id: "51tw2cm5x", name: "total_adult", value: properties.totalAdult});
  eventPropertiesArray.push({id: "1LLiSe1m8", name: "total_child", value: properties.totalChild});
  eventPropertiesArray.push({id: "ti-NPDgql", name: "total_infant", value: properties.totalInfant});
  eventPropertiesArray.push({id: "BOqt1XTFX", name: "load_time", value: properties.loadTime});
  eventPropertiesArray.push({id: "8iH9ZXom0V", name: "searchType", value: properties.searchType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProducts(properties.products));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7822sLDIY8", "f2fc1674f254092acaa7a23fd352a36abeb74cb252603c9f4f54d2c4811675c3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product List Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7822sLDIY8", "Product List Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product List Viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product List Viewed", eventProperties, "7822sLDIY8", "f2fc1674f254092acaa7a23fd352a36abeb74cb252603c9f4f54d2c4811675c3");
    }
    // destination RudderStack
    RudderStack.logEvent("Product List Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface EmptySearchResultsProperties {
  loadTime: string;
  searchType: SearchTypeValueType;
}
/**
 * Empty Search Results: Pushed when empty search results
 *
 * @param properties the properties associatied with this event
 * @param properties.loadTime: no description
 * @param properties.searchType: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/lTUDJ_qL79}
 */
export function emptySearchResults(properties: EmptySearchResultsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "BOqt1XTFX", name: "load_time", value: properties.loadTime});
  eventPropertiesArray.push({id: "8iH9ZXom0V", name: "searchType", value: properties.searchType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lTUDJ_qL79", "324f32c1d61c5ce5948f94fbc4b97762b0d400d589c008bad055936ac5f704a6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Empty Search Results", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lTUDJ_qL79", "Empty Search Results", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Empty Search Results", eventProperties, "lTUDJ_qL79", "324f32c1d61c5ce5948f94fbc4b97762b0d400d589c008bad055936ac5f704a6");
    }
    // destination RudderStack
    RudderStack.logEvent("Empty Search Results", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductClickedProperties {
  productId: string;
  name: string;
  price: number;
  currency: string;
  brand: string;
  category: string | null | undefined;
  url: string;
  position: number;
  variant: VariantValueType | null | undefined;
  offerTags: string[];
  searchType: SearchTypeValueType;
}
/**
 * Product Clicked: Product clicked in search result
 *
 * @param properties the properties associatied with this event
 * @param properties.productId: Product id
 * @param properties.name: Combined list of route stations, separated by -
 * @param properties.price: Total price
 * @param properties.currency: Currency
 * @param properties.brand: Combined names of vendors
 * @param properties.category: Combined bundle names
 * @param properties.url: no description
 * @param properties.position: The position of the product displayed in a product list
 * @param properties.variant: oneway or roundtrip
 * @param properties.offerTags: Used to tag offers results in the search as CHEAPEST, QUICKEST, RECOMMENDED
 * @param properties.searchType: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/EXjHpdmmJA}
 */
export function productClicked(properties: ProductClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "cKe5Hmy8ZL", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "54ipXOV7J", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "A996THL8A0", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "XQPbaMzvU2", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "F5qDntpfi", name: "brand", value: properties.brand});
  properties.category !== undefined && properties.category !== null ?
    eventPropertiesArray.push({id: "zWkdma-jF", name: "category", value: properties.category}) :
    eventPropertiesArray.push({id: "zWkdma-jF", name: "category", value: null});
  eventPropertiesArray.push({id: "CExMHmKTh", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "QzF8Y-Ypj2", name: "position", value: properties.position});
  properties.variant !== undefined && properties.variant !== null ?
    eventPropertiesArray.push({id: "Cl91WzyT4", name: "variant", value: properties.variant}) :
    eventPropertiesArray.push({id: "Cl91WzyT4", name: "variant", value: null});
  eventPropertiesArray.push({id: "KU1oWfP0P", name: "offer tags", value: properties.offerTags});
  eventPropertiesArray.push({id: "8iH9ZXom0V", name: "searchType", value: properties.searchType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "EXjHpdmmJA", "6cc73e4f149b62ab1e472df65bebc24dca67117b13313a00a4f11630b62a18aa", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("EXjHpdmmJA", "Product Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Clicked", eventProperties, "EXjHpdmmJA", "6cc73e4f149b62ab1e472df65bebc24dca67117b13313a00a4f11630b62a18aa");
    }
    // destination RudderStack
    RudderStack.logEvent("Product Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductViewedProperties {
  productId: string;
  name: string;
  brand: string;
  category: string | null | undefined;
  price: number;
  currency: string;
  url: string;
  position: number;
  variant: VariantValueType | null | undefined;
  loadTime: string;
}
/**
 * Product Viewed: Product viewed on booking step
 *
 * @param properties the properties associatied with this event
 * @param properties.productId: Product id
 * @param properties.name: Combined list of route stations, separated by -
 * @param properties.brand: Combined names of vendors
 * @param properties.category: Combined bundle names
 * @param properties.price: Total price
 * @param properties.currency: Currency
 * @param properties.url: no description
 * @param properties.position: The position of the product displayed in a product list
 * @param properties.variant: oneway or roundtrip
 * @param properties.loadTime: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/4pTOV_fWuP}
 */
export function productViewed(properties: ProductViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "cKe5Hmy8ZL", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "54ipXOV7J", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "F5qDntpfi", name: "brand", value: properties.brand});
  properties.category !== undefined && properties.category !== null ?
    eventPropertiesArray.push({id: "zWkdma-jF", name: "category", value: properties.category}) :
    eventPropertiesArray.push({id: "zWkdma-jF", name: "category", value: null});
  eventPropertiesArray.push({id: "A996THL8A0", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "XQPbaMzvU2", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "CExMHmKTh", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "QzF8Y-Ypj2", name: "position", value: properties.position});
  properties.variant !== undefined && properties.variant !== null ?
    eventPropertiesArray.push({id: "Cl91WzyT4", name: "variant", value: properties.variant}) :
    eventPropertiesArray.push({id: "Cl91WzyT4", name: "variant", value: null});
  eventPropertiesArray.push({id: "BOqt1XTFX", name: "load_time", value: properties.loadTime});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4pTOV_fWuP", "dd038a8758c6b81ee0bd7506fa80d049d49de590a766d9100853e6ec5c56dfc3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4pTOV_fWuP", "Product Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Viewed", eventProperties, "4pTOV_fWuP", "dd038a8758c6b81ee0bd7506fa80d049d49de590a766d9100853e6ec5c56dfc3");
    }
    // destination RudderStack
    RudderStack.logEvent("Product Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrderCompletedProperties {
  products: Products[];
  orderId: string;
  total: number;
  currency: string;
  totalAdult: number;
  totalChild: number;
  totalInfant: number;
  departureDate: string;
  returnDate: string | null | undefined;
  language: string;
  paymentMethod: string;
  partner: string;
}
/**
 * Order Completed: Purchase event

 *
 * @param properties the properties associatied with this event
 * @param properties.products: Products
 * @param properties.orderId: no description
 * @param properties.total: no description
 * @param properties.currency: Currency
 * @param properties.totalAdult: no description
 * @param properties.totalChild: no description
 * @param properties.totalInfant: no description
 * @param properties.departureDate: no description
 * @param properties.returnDate: no description
 * @param properties.language: no description
 * @param properties.paymentMethod: no description
 * @param properties.partner: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/svHGOWghTK}
 */
export function orderCompleted(properties: OrderCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "M79QMSmcZN", name: "products", value: properties.products.map(function(value) { return {
      "price": value.price,
      "currency": value.currency,
      "product_id": value.productId,
      "position": value.position,
      "brand": value.brand,
      "category": value.category,
      "name": value.name,
      "variant": value.variant,
      "quantity": value.quantity,
    }
  })});
  eventPropertiesArray.push({id: "2vhaP7wvL", name: "order_id", value: properties.orderId});
  eventPropertiesArray.push({id: "Y48C31dQT", name: "total", value: properties.total});
  eventPropertiesArray.push({id: "XQPbaMzvU2", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "51tw2cm5x", name: "total_adult", value: properties.totalAdult});
  eventPropertiesArray.push({id: "1LLiSe1m8", name: "total_child", value: properties.totalChild});
  eventPropertiesArray.push({id: "ti-NPDgql", name: "total_infant", value: properties.totalInfant});
  eventPropertiesArray.push({id: "exs__oqOs", name: "departure_date", value: properties.departureDate});
  properties.returnDate !== undefined && properties.returnDate !== null ?
    eventPropertiesArray.push({id: "ovf0RREol", name: "return_date", value: properties.returnDate}) :
    eventPropertiesArray.push({id: "ovf0RREol", name: "return_date", value: null});
  eventPropertiesArray.push({id: "1C1MMQqLD", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "naaqzZHAC", name: "payment_method", value: properties.paymentMethod});
  eventPropertiesArray.push({id: "5cevT9HzU", name: "partner", value: properties.partner});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProducts(properties.products));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "svHGOWghTK", "630c99c3b974a1b2be82ddd2b30d1af59519ebe794e394d5647e3c0ecf77868e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Order Completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("svHGOWghTK", "Order Completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Order Completed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order Completed", eventProperties, "svHGOWghTK", "630c99c3b974a1b2be82ddd2b30d1af59519ebe794e394d5647e3c0ecf77868e");
    }
    // destination RudderStack
    RudderStack.logEvent("Order Completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface BundleSelectedProperties {
  bundleName: string;
  loadTime: string;
}
/**
 * Bundle Selected: Send when bundle is selected
 *
 * @param properties the properties associatied with this event
 * @param properties.bundleName: no description
 * @param properties.loadTime: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/Hr4Ra0Gpgt}
 */
export function bundleSelected(properties: BundleSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "TpeaTlPeb", name: "bundle_name", value: properties.bundleName});
  eventPropertiesArray.push({id: "BOqt1XTFX", name: "load_time", value: properties.loadTime});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Hr4Ra0Gpgt", "b79ad40ed306a7c1add771418319b104f0a4279d88d9e98466206c4dd0cdad3e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Bundle Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Hr4Ra0Gpgt", "Bundle Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Bundle Selected", eventProperties, "Hr4Ra0Gpgt", "b79ad40ed306a7c1add771418319b104f0a4279d88d9e98466206c4dd0cdad3e");
    }
    // destination RudderStack
    RudderStack.logEvent("Bundle Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AddServiceProperties {
  quantity: number;
  weight: number | null | undefined;
  productId: string;
}
/**
 * Add Service: Send when a luggage is added
 *
 * @param properties the properties associatied with this event
 * @param properties.quantity: no description
 * @param properties.weight: no description
 * @param properties.productId: Product id
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/bTWSJ6luXX}
 */
export function addService(properties: AddServiceProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "V9VsSth8i", name: "quantity", value: properties.quantity});
  properties.weight !== undefined && properties.weight !== null ?
    eventPropertiesArray.push({id: "PLJdaJH1m", name: "weight", value: properties.weight}) :
    eventPropertiesArray.push({id: "PLJdaJH1m", name: "weight", value: null});
  eventPropertiesArray.push({id: "cKe5Hmy8ZL", name: "product_id", value: properties.productId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bTWSJ6luXX", "cc94f2b0bc2f56d1a228a8e70c7b2b1d6a29a01616e88bfd6d6a2a1dcbf23a8d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Add Service", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bTWSJ6luXX", "Add Service", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Add Service", eventProperties, "bTWSJ6luXX", "cc94f2b0bc2f56d1a228a8e70c7b2b1d6a29a01616e88bfd6d6a2a1dcbf23a8d");
    }
    // destination RudderStack
    RudderStack.logEvent("Add Service", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface UpdatePassengersProperties {
  totalAdult: number;
  totalChild: number;
  loadTime: string;
}
/**
 * Update Passengers: Update passengers
 *
 * @param properties the properties associatied with this event
 * @param properties.totalAdult: no description
 * @param properties.totalChild: no description
 * @param properties.loadTime: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/LuT_7B6des}
 */
export function updatePassengers(properties: UpdatePassengersProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "51tw2cm5x", name: "total_adult", value: properties.totalAdult});
  eventPropertiesArray.push({id: "1LLiSe1m8", name: "total_child", value: properties.totalChild});
  eventPropertiesArray.push({id: "BOqt1XTFX", name: "load_time", value: properties.loadTime});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "LuT_7B6des", "57c5c26566b6af759fc09726d3c80930b0d84c5702407d9acc21d881664c51b5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Update Passengers", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("LuT_7B6des", "Update Passengers", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Update Passengers", eventProperties, "LuT_7B6des", "57c5c26566b6af759fc09726d3c80930b0d84c5702407d9acc21d881664c51b5");
    }
    // destination RudderStack
    RudderStack.logEvent("Update Passengers", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Service Mismatch: Sent when an ancillary mismatch is send
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/zcy3Dm3FDl}
 */
export function serviceMismatch() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zcy3Dm3FDl", "8ad726f17a5cf373471882b3525135a06e974a4a85eb0f09c066d0432ad64438", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Service Mismatch", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zcy3Dm3FDl", "Service Mismatch", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Service Mismatch", eventProperties, "zcy3Dm3FDl", "8ad726f17a5cf373471882b3525135a06e974a4a85eb0f09c066d0432ad64438");
    }
    // destination RudderStack
    RudderStack.logEvent("Service Mismatch", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PriceMismatchProperties {
  oldPrice: number;
  newPrice: number;
  currency: string;
  isSwallow: boolean;
}
/**
 * Price Mismatch: Send when we have price mismatch on the booking page
 *
 * @param properties the properties associatied with this event
 * @param properties.oldPrice: no description
 * @param properties.newPrice: no description
 * @param properties.currency: Currency
 * @param properties.isSwallow: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/hbm8W0_fLa}
 */
export function priceMismatch(properties: PriceMismatchProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "cwWiNzHas", name: "old_price", value: properties.oldPrice});
  eventPropertiesArray.push({id: "zIERAeyWv", name: "new_price", value: properties.newPrice});
  eventPropertiesArray.push({id: "XQPbaMzvU2", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "TSgM6maCh", name: "isSwallow", value: properties.isSwallow});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "hbm8W0_fLa", "3cf0e879f2b8eee0ba681b0b3d1f89e8a31b34248027b9270170c9ad40efc133", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Price Mismatch", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("hbm8W0_fLa", "Price Mismatch", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Price Mismatch", eventProperties, "hbm8W0_fLa", "3cf0e879f2b8eee0ba681b0b3d1f89e8a31b34248027b9270170c9ad40efc133");
    }
    // destination RudderStack
    RudderStack.logEvent("Price Mismatch", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PaymentInfoEnteredProperties {
  checkoutId: string;
  orderId: string;
  paymentMethod: string;
  currency: string;
  total: number;
  products: Products[];
}
/**
 * Payment Info Entered: Payment info has been entered
 *
 * @param properties the properties associatied with this event
 * @param properties.checkoutId: no description
 * @param properties.orderId: no description
 * @param properties.paymentMethod: no description
 * @param properties.currency: Currency
 * @param properties.total: no description
 * @param properties.products: Products
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/jnFJD_qew}
 */
export function paymentInfoEntered(properties: PaymentInfoEnteredProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qTrgzJWq5", name: "checkout_id", value: properties.checkoutId});
  eventPropertiesArray.push({id: "2vhaP7wvL", name: "order_id", value: properties.orderId});
  eventPropertiesArray.push({id: "naaqzZHAC", name: "payment_method", value: properties.paymentMethod});
  eventPropertiesArray.push({id: "XQPbaMzvU2", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "Y48C31dQT", name: "total", value: properties.total});
  eventPropertiesArray.push({id: "M79QMSmcZN", name: "products", value: properties.products.map(function(value) { return {
      "price": value.price,
      "currency": value.currency,
      "product_id": value.productId,
      "position": value.position,
      "brand": value.brand,
      "category": value.category,
      "name": value.name,
      "variant": value.variant,
      "quantity": value.quantity,
    }
  })});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProducts(properties.products));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jnFJD_qew", "2268dddd7e7a2b89f81f541dbe9b9d8fe575a4d2947591fea318edceb0955fb2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Payment Info Entered", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jnFJD_qew", "Payment Info Entered", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Payment Info Entered': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Payment Info Entered", eventProperties, "jnFJD_qew", "2268dddd7e7a2b89f81f541dbe9b9d8fe575a4d2947591fea318edceb0955fb2");
    }
    // destination RudderStack
    RudderStack.logEvent("Payment Info Entered", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductListFilteredProperties {
  products: Products[];
  filters: Filters[];
  sorts: Sorts[];
  searchType: SearchTypeValueType;
}
/**
 * Product List Filtered: Sent on search result page when filteres are selected
 *
 * @param properties the properties associatied with this event
 * @param properties.products: Products
 * @param properties.filters: no description
 * @param properties.sorts: no description
 * @param properties.searchType: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/PaCI403xKL}
 */
export function productListFiltered(properties: ProductListFilteredProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "M79QMSmcZN", name: "products", value: properties.products.map(function(value) { return {
      "price": value.price,
      "currency": value.currency,
      "product_id": value.productId,
      "position": value.position,
      "brand": value.brand,
      "category": value.category,
      "name": value.name,
      "variant": value.variant,
      "quantity": value.quantity,
    }
  })});
  eventPropertiesArray.push({id: "E0WG65Mqcj", name: "filters", value: properties.filters.map(function(value) { return {
      "type": value.type,
      "value": value.value,
    }
  })});
  eventPropertiesArray.push({id: "AL2GN62Cpe", name: "sorts", value: properties.sorts.map(function(value) { return {
      "type": value.type,
      "value": value.value,
    }
  })});
  eventPropertiesArray.push({id: "8iH9ZXom0V", name: "searchType", value: properties.searchType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProducts(properties.products));
    messages = messages.concat(assertFilters(properties.filters));
    messages = messages.concat(assertSorts(properties.sorts));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "PaCI403xKL", "36da918ddb36fee362f2a61712f40c98d28de5e7f48e8c6501a2c307b178240d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product List Filtered", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("PaCI403xKL", "Product List Filtered", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product List Filtered': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product List Filtered", eventProperties, "PaCI403xKL", "36da918ddb36fee362f2a61712f40c98d28de5e7f48e8c6501a2c307b178240d");
    }
    // destination RudderStack
    RudderStack.logEvent("Product List Filtered", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ButtonClickedProperties {
  id: string;
  path: string;
}
/**
 * Button Clicked: Generic button clicked event
 *
 * @param properties the properties associatied with this event
 * @param properties.id: no description
 * @param properties.path: Page path
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/vDQvehEE4J}
 */
export function buttonClicked(properties: ButtonClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "6l1IZAaA4", name: "id", value: properties.id});
  eventPropertiesArray.push({id: "JRme9k-Vcu", name: "path", value: properties.path});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vDQvehEE4J", "ee04c9ea05c9fbbc8505801ad4e5606140efb8715094e7a4e5f4d55098d693af", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Button Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vDQvehEE4J", "Button Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Button Clicked", eventProperties, "vDQvehEE4J", "ee04c9ea05c9fbbc8505801ad4e5606140efb8715094e7a4e5f4d55098d693af");
    }
    // destination RudderStack
    RudderStack.logEvent("Button Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PopupShownProperties {
  id: string;
}
/**
 * Popup Shown: Generic
 *
 * @param properties the properties associatied with this event
 * @param properties.id: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/Iny1CRyLPJ}
 */
export function popupShown(properties: PopupShownProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "6l1IZAaA4", name: "id", value: properties.id});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Iny1CRyLPJ", "22a11820c8601da7fa06db612e1598be8470f757ddb045265507cfc42bf27af4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Popup Shown", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Iny1CRyLPJ", "Popup Shown", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Popup Shown", eventProperties, "Iny1CRyLPJ", "22a11820c8601da7fa06db612e1598be8470f757ddb045265507cfc42bf27af4");
    }
    // destination RudderStack
    RudderStack.logEvent("Popup Shown", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ModalShownProperties {
  id: string;
}
/**
 * Modal Shown: Generic modal shown
 *
 * @param properties the properties associatied with this event
 * @param properties.id: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/F-XsMv7ZQW}
 */
export function modalShown(properties: ModalShownProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "6l1IZAaA4", name: "id", value: properties.id});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "F-XsMv7ZQW", "1e08141e5d24f14501ee80f56f8812ea145fbec84670dcda2eed8287f1299fbe", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Modal Shown", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("F-XsMv7ZQW", "Modal Shown", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Modal Shown", eventProperties, "F-XsMv7ZQW", "1e08141e5d24f14501ee80f56f8812ea145fbec84670dcda2eed8287f1299fbe");
    }
    // destination RudderStack
    RudderStack.logEvent("Modal Shown", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Partial Passenger Info Entered: Send for partial passenger info form entered
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/6b3g-8PU9k}
 */
export function partialPassengerInfoEntered() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6b3g-8PU9k", "4a193bd3f5dd81d8fee1f0d5b8ed9659abc46e14ce0d1a2389fda3d1d93ebb11", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Partial Passenger Info Entered", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6b3g-8PU9k", "Partial Passenger Info Entered", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Partial Passenger Info Entered", eventProperties, "6b3g-8PU9k", "4a193bd3f5dd81d8fee1f0d5b8ed9659abc46e14ce0d1a2389fda3d1d93ebb11");
    }
    // destination RudderStack
    RudderStack.logEvent("Partial Passenger Info Entered", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Passenger Info Entered: Send when passenger form has been completed
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/_vJCK6phbF}
 */
export function passengerInfoEntered() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_vJCK6phbF", "ee5ee9f11643bb030f81277a39a01436945e756a8dbfc92272a6ab9e905058d4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Passenger Info Entered", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_vJCK6phbF", "Passenger Info Entered", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Passenger Info Entered", eventProperties, "_vJCK6phbF", "ee5ee9f11643bb030f81277a39a01436945e756a8dbfc92272a6ab9e905058d4");
    }
    // destination RudderStack
    RudderStack.logEvent("Passenger Info Entered", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Contact Info Entered: Send when contact info is entered
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/meKHwll-_1}
 */
export function contactInfoEntered() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "meKHwll-_1", "1e4b46b5f070501884ef86f1639fd2ec53af44ed47fb75c17a42f64c830f1c17", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Contact Info Entered", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("meKHwll-_1", "Contact Info Entered", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Contact Info Entered", eventProperties, "meKHwll-_1", "1e4b46b5f070501884ef86f1639fd2ec53af44ed47fb75c17a42f64c830f1c17");
    }
    // destination RudderStack
    RudderStack.logEvent("Contact Info Entered", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Billing Address Entered: Send when Billing address has been entered
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/OaDRfhAHW5}
 */
export function billingAddressEntered() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "OaDRfhAHW5", "cf11692c30b8c523051882e75730071f29870bdc296ac0dc16252c86c1796e4e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Billing Address Entered", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("OaDRfhAHW5", "Billing Address Entered", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Billing Address Entered", eventProperties, "OaDRfhAHW5", "cf11692c30b8c523051882e75730071f29870bdc296ac0dc16252c86c1796e4e");
    }
    // destination RudderStack
    RudderStack.logEvent("Billing Address Entered", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Partial Passenger Info Started: Send when partial passenger info form has an interaction
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/kqrSQbYniW}
 */
export function partialPassengerInfoStarted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kqrSQbYniW", "4f2c9e7826a1289e376c232d5159ec0d5dbe9d02a2c01bfd7027b6ace51339b3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Partial Passenger Info Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kqrSQbYniW", "Partial Passenger Info Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Partial Passenger Info Started", eventProperties, "kqrSQbYniW", "4f2c9e7826a1289e376c232d5159ec0d5dbe9d02a2c01bfd7027b6ace51339b3");
    }
    // destination RudderStack
    RudderStack.logEvent("Partial Passenger Info Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Passenger Info Started: Send when passenger info form has an interaction
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/7zXlRFIti-}
 */
export function passengerInfoStarted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7zXlRFIti-", "e82e32338d5c23fa5c1326955a228f3d8d5311672040a6876d77935b5a0c704c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Passenger Info Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7zXlRFIti-", "Passenger Info Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Passenger Info Started", eventProperties, "7zXlRFIti-", "e82e32338d5c23fa5c1326955a228f3d8d5311672040a6876d77935b5a0c704c");
    }
    // destination RudderStack
    RudderStack.logEvent("Passenger Info Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Billing Address Started: Send when the billing info form has an interaction
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/JBIE06xG-3}
 */
export function billingAddressStarted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "JBIE06xG-3", "cbab49bf8745e9794010ec5e6637c9e5cbbe8e0c8520c08a78d989a27df10b36", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Billing Address Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("JBIE06xG-3", "Billing Address Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Billing Address Started", eventProperties, "JBIE06xG-3", "cbab49bf8745e9794010ec5e6637c9e5cbbe8e0c8520c08a78d989a27df10b36");
    }
    // destination RudderStack
    RudderStack.logEvent("Billing Address Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Contact Info Started: Send when contact info form has an interaction
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/O_-8hFB4ca}
 */
export function contactInfoStarted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O_-8hFB4ca", "ed534545e2fade0e500934306b6dbd93f97f00df1e46de0e2d2aa99425de8bb1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Contact Info Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O_-8hFB4ca", "Contact Info Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Contact Info Started", eventProperties, "O_-8hFB4ca", "ed534545e2fade0e500934306b6dbd93f97f00df1e46de0e2d2aa99425de8bb1");
    }
    // destination RudderStack
    RudderStack.logEvent("Contact Info Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AdditionalServicePurchaseProperties {
  price: number;
  productId: string;
  currency: string;
  quantity: number;
}
/**
 * Additional Service Purchase: Send when an additional service has been purchased, sent on a leg basis
 *
 * @param properties the properties associatied with this event
 * @param properties.price: Total price
 * @param properties.productId: Product id
 * @param properties.currency: Currency
 * @param properties.quantity: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/73YP4pzyl5}
 */
export function additionalServicePurchase(
  properties: AdditionalServicePurchaseProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "A996THL8A0", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "cKe5Hmy8ZL", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "XQPbaMzvU2", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "V9VsSth8i", name: "quantity", value: properties.quantity});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "73YP4pzyl5", "c8e1f5816329f2f9eb700277d441bf8d91ec1115ff48d58693eb28e027178456", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Additional Service Purchase", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("73YP4pzyl5", "Additional Service Purchase", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Additional Service Purchase", eventProperties, "73YP4pzyl5", "c8e1f5816329f2f9eb700277d441bf8d91ec1115ff48d58693eb28e027178456");
    }
    // destination RudderStack
    RudderStack.logEvent("Additional Service Purchase", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Payment Form Started: Send when the user start interacting with the passenger form
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/AyrcEhyAwF}
 */
export function paymentFormStarted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "AyrcEhyAwF", "a684b644d877971e7eb1a707c34caf55c75a14f4dec6a808e21ca8dd077109fd", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Payment Form Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("AyrcEhyAwF", "Payment Form Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Payment Form Started", eventProperties, "AyrcEhyAwF", "a684b644d877971e7eb1a707c34caf55c75a14f4dec6a808e21ca8dd077109fd");
    }
    // destination RudderStack
    RudderStack.logEvent("Payment Form Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LinkClickedProperties {
  url: string | null | undefined;
  path: string;
}
/**
 * Link Clicked: A generic event is sent when a link element is clicked
 *
 * @param properties the properties associatied with this event
 * @param properties.url: no description
 * @param properties.path: Page path
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/gqPNHI_9NF}
 */
export function linkClicked(properties: LinkClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.url !== undefined && properties.url !== null ?
    eventPropertiesArray.push({id: "CExMHmKTh", name: "url", value: properties.url}) :
    eventPropertiesArray.push({id: "CExMHmKTh", name: "url", value: null});
  eventPropertiesArray.push({id: "JRme9k-Vcu", name: "path", value: properties.path});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "gqPNHI_9NF", "3f51c1c9c7ced78b069bdb0b6ae5c668a2a16ed752677c5d7510cbb44cb919dc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Link Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("gqPNHI_9NF", "Link Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Link Clicked", eventProperties, "gqPNHI_9NF", "3f51c1c9c7ced78b069bdb0b6ae5c668a2a16ed752677c5d7510cbb44cb919dc");
    }
    // destination RudderStack
    RudderStack.logEvent("Link Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TooltipShownProperties {
  id: string;
}
/**
 * Tooltip Shown: Send when a tooltip is shown.
 *
 * @param properties the properties associatied with this event
 * @param properties.id: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/4scZ2WR6kh}
 */
export function tooltipShown(properties: TooltipShownProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "6l1IZAaA4", name: "id", value: properties.id});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4scZ2WR6kh", "fe1db1827f874b488dd13da8f563a0d0453f606a398e37bcd937d648c4663f81", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Tooltip Shown", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4scZ2WR6kh", "Tooltip Shown", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Tooltip Shown", eventProperties, "4scZ2WR6kh", "fe1db1827f874b488dd13da8f563a0d0453f606a398e37bcd937d648c4663f81");
    }
    // destination RudderStack
    RudderStack.logEvent("Tooltip Shown", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PCIProxyLoadedProperties {
  loadTime: string;
}
/**
 * PCIProxy Loaded: Send when the PCI proxy iframe has loaded
 *
 * @param properties the properties associatied with this event
 * @param properties.loadTime: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/x2cBrQ66Cw}
 */
export function pciproxyLoaded(properties: PCIProxyLoadedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "BOqt1XTFX", name: "load_time", value: properties.loadTime});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "x2cBrQ66Cw", "eb58217f871f4320d1106a89756e0e9b32895d3aac10ca08288a7fdb2df54026", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("PCIProxy Loaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("x2cBrQ66Cw", "PCIProxy Loaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("PCIProxy Loaded", eventProperties, "x2cBrQ66Cw", "eb58217f871f4320d1106a89756e0e9b32895d3aac10ca08288a7fdb2df54026");
    }
    // destination RudderStack
    RudderStack.logEvent("PCIProxy Loaded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Product Viewed Error: Send when loading a product fails
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/n3DH1Mx3l8}
 */
export function productViewedError() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "n3DH1Mx3l8", "e21a9293e4e9d02ac4a33ffe9dc91f7ceab5320e290ff2a2c9cf7cf932444c93", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Viewed Error", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("n3DH1Mx3l8", "Product Viewed Error", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Viewed Error", eventProperties, "n3DH1Mx3l8", "e21a9293e4e9d02ac4a33ffe9dc91f7ceab5320e290ff2a2c9cf7cf932444c93");
    }
    // destination RudderStack
    RudderStack.logEvent("Product Viewed Error", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrderPriceMismatchProperties {
  oldPrice: number;
  newPrice: number;
  currency: string;
}
/**
 * Order Price Mismatch: Send during order price mismatch
 *
 * @param properties the properties associatied with this event
 * @param properties.oldPrice: no description
 * @param properties.newPrice: no description
 * @param properties.currency: Currency
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/xv6wUkCheR}
 */
export function orderPriceMismatch(properties: OrderPriceMismatchProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "cwWiNzHas", name: "old_price", value: properties.oldPrice});
  eventPropertiesArray.push({id: "zIERAeyWv", name: "new_price", value: properties.newPrice});
  eventPropertiesArray.push({id: "XQPbaMzvU2", name: "currency", value: properties.currency});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "xv6wUkCheR", "1995f208316c308f0698e053c3ef066b32df1035518916e332e8684e604e28dd", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Order Price Mismatch", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("xv6wUkCheR", "Order Price Mismatch", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order Price Mismatch", eventProperties, "xv6wUkCheR", "1995f208316c308f0698e053c3ef066b32df1035518916e332e8684e604e28dd");
    }
    // destination RudderStack
    RudderStack.logEvent("Order Price Mismatch", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CreateOrderErrorProperties {
  errorType: string;
  isRecoverable: boolean;
}
/**
 * Create Order Error: Sent when an error occurs during create order
 *
 * @param properties the properties associatied with this event
 * @param properties.errorType: no description
 * @param properties.isRecoverable: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/AmIORKioTB}
 */
export function createOrderError(properties: CreateOrderErrorProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "3WG3LWcFG", name: "error_type", value: properties.errorType});
  eventPropertiesArray.push({id: "hDI5GUCPN", name: "is_recoverable", value: properties.isRecoverable});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "AmIORKioTB", "73d123cc6005e5684c54ec307ea47d7ed65bf6a273d63ee606451a6d3d758be0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Create Order Error", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("AmIORKioTB", "Create Order Error", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Create Order Error", eventProperties, "AmIORKioTB", "73d123cc6005e5684c54ec307ea47d7ed65bf6a273d63ee606451a6d3d758be0");
    }
    // destination RudderStack
    RudderStack.logEvent("Create Order Error", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Billing Address Submitted: Send when the billing info form has been submitted to the backend
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/jjwGEEzpcF}
 */
export function billingAddressSubmitted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jjwGEEzpcF", "1894f1e8d8649809a3ffcf3c2eb0461168042cdb37d1b24e8a149ce92e9fe261", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Billing Address Submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jjwGEEzpcF", "Billing Address Submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Billing Address Submitted", eventProperties, "jjwGEEzpcF", "1894f1e8d8649809a3ffcf3c2eb0461168042cdb37d1b24e8a149ce92e9fe261");
    }
    // destination RudderStack
    RudderStack.logEvent("Billing Address Submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Contact Info Submitted: Send when contact info has been submitted to the backend
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/z17VTcpkQ_}
 */
export function contactInfoSubmitted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "z17VTcpkQ_", "64fc9968084253d9240107c64a24ce411ab8dffebd2ca765875c3dbc2816e1b2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Contact Info Submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("z17VTcpkQ_", "Contact Info Submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Contact Info Submitted", eventProperties, "z17VTcpkQ_", "64fc9968084253d9240107c64a24ce411ab8dffebd2ca765875c3dbc2816e1b2");
    }
    // destination RudderStack
    RudderStack.logEvent("Contact Info Submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Partial Passenger Info Submitted: Send for partial passenger info form has been submitted to the backend.
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/U7Zo16lZfH}
 */
export function partialPassengerInfoSubmitted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "U7Zo16lZfH", "5892e53c47947cfa6a011e5e7e184866d41562c6fe52aa09b4c903ed2ca16fdf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Partial Passenger Info Submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("U7Zo16lZfH", "Partial Passenger Info Submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Partial Passenger Info Submitted", eventProperties, "U7Zo16lZfH", "5892e53c47947cfa6a011e5e7e184866d41562c6fe52aa09b4c903ed2ca16fdf");
    }
    // destination RudderStack
    RudderStack.logEvent("Partial Passenger Info Submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Passenger Info Submitted: Send when passenger form has been submitted
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/RNay8Iq2Fm}
 */
export function passengerInfoSubmitted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "RNay8Iq2Fm", "804d4aca954fb5de12378301abf4638ae526445734008503a1ac805e9cfda2b4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Passenger Info Submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("RNay8Iq2Fm", "Passenger Info Submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Passenger Info Submitted", eventProperties, "RNay8Iq2Fm", "804d4aca954fb5de12378301abf4638ae526445734008503a1ac805e9cfda2b4");
    }
    // destination RudderStack
    RudderStack.logEvent("Passenger Info Submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ClearJourneySelectionProperties {
  searchType: SearchTypeValueType;
}
/**
 * Clear Journey Selection: Send when the outbound/inbound selection is cleared
 *
 * @param properties the properties associatied with this event
 * @param properties.searchType: no description
 *
 * @see {@link https://www.avo.app/schemas/KJttCUBS93c9o5mHkigS/events/T_GSipItRl}
 */
export function clearJourneySelection(
  properties: ClearJourneySelectionProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8iH9ZXom0V", name: "searchType", value: properties.searchType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "T_GSipItRl", "8ae3aa136faf61e14c73efa86a906ed44245d0a1cfb36ad264a22a47be3023ec", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Clear Journey Selection", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("T_GSipItRl", "Clear Journey Selection", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Clear Journey Selection", eventProperties, "T_GSipItRl", "8ae3aa136faf61e14c73efa86a906ed44245d0a1cfb36ad264a22a47be3023ec");
    }
    // destination RudderStack
    RudderStack.logEvent("Clear Journey Selection", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  SearchType,
  Variant,
  pageview,
  productListViewed,
  emptySearchResults,
  productClicked,
  productViewed,
  orderCompleted,
  bundleSelected,
  addService,
  updatePassengers,
  serviceMismatch,
  priceMismatch,
  paymentInfoEntered,
  productListFiltered,
  buttonClicked,
  popupShown,
  modalShown,
  partialPassengerInfoEntered,
  passengerInfoEntered,
  contactInfoEntered,
  billingAddressEntered,
  partialPassengerInfoStarted,
  passengerInfoStarted,
  billingAddressStarted,
  contactInfoStarted,
  additionalServicePurchase,
  paymentFormStarted,
  linkClicked,
  tooltipShown,
  pciproxyLoaded,
  productViewedError,
  orderPriceMismatch,
  createOrderError,
  billingAddressSubmitted,
  contactInfoSubmitted,
  partialPassengerInfoSubmitted,
  passengerInfoSubmitted,
  clearJourneySelection,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["pageview","productListViewed","emptySearchResults","productClicked","productViewed","orderCompleted","bundleSelected","addService","updatePassengers","serviceMismatch","priceMismatch","paymentInfoEntered","productListFiltered","buttonClicked","popupShown","modalShown","partialPassengerInfoEntered","passengerInfoEntered","contactInfoEntered","billingAddressEntered","partialPassengerInfoStarted","passengerInfoStarted","billingAddressStarted","contactInfoStarted","additionalServicePurchase","paymentFormStarted","linkClicked","tooltipShown","pciproxyLoaded","productViewedError","orderPriceMismatch","createOrderError","billingAddressSubmitted","contactInfoSubmitted","partialPassengerInfoSubmitted","passengerInfoSubmitted","clearJourneySelection"]
