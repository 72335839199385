import { css } from '@emotion/react';

const voloteaFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Trade Gothic Bold';
    src: url('/fonts/TradeGothicLT-Bold.woff2') format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Trade Gothic Condensed No. 20';
    src: url('/fonts/TradeGothicLTStd.woff2') format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Trade Gothic Bold Condensed No. 20';
    src: url('/fonts/TradeGothicLTStd-BdCn20.woff2') format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Arial;
    src: url('/fonts/Volotea/Arial.woff2');
  }
`;

export default voloteaFonts;
