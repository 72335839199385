import { css } from '@emotion/react';

// TODO: Once everything is live and the CMS is up to date, we can remove the Boogie School declarations
const dohopconnectFonts = css`
  @font-face {
    font-family: Montserrat-Regular;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/DohopConnect/TTInterfaces-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/DohopConnect/TTInterfaces-Light.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/DohopConnect/TTInterfaces-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/DohopConnect/TTInterfaces-Medium.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/DohopConnect/TTInterfaces-SemiBold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/DohopConnect/TTInterfaces-Bold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'TT Interfaces';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/DohopConnect/TTInterfaces-Light.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'TT Interfaces';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/DohopConnect/TTInterfaces-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'TT Interfaces';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/DohopConnect/TTInterfaces-Medium.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Boogie School Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/DohopConnect/TTInterfaces-SemiBold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'TT Interfaces';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/DohopConnect/TTInterfaces-Bold.woff2');
  }
`;

export default dohopconnectFonts;
