import dayjs from 'dayjs';
import {
  OfferFragment,
  ItineraryFragment,
  OfferFilters,
  RouteFragment,
} from '@codegen/gatewayUtils';
import {
  OfferResponse,
  PaxType,
  LegGroupSummary,
  ItineraryWithLegCombo,
  Leg,
  Passenger,
  Summary,
  OrderSummaryResponseBookingsItem,
  ErrorCode,
} from '@codegen/offerAPI';
import {
  CMSPassengerType,
  CurrencyCode,
  Language,
  Partner,
  Sort,
} from '@shared/types/enums';
import { toUTCLocaleString } from '@utils/dateUtils';
import { isDeepEqual, partition } from '@utils/helperUtils';
import { Experiment } from '@web/types/experimentTypes';
import {
  productListViewed,
  emptySearchResults,
  productClicked,
  productViewed,
  priceMismatch,
  addService,
  serviceMismatch,
  updatePassengers,
  UpdatePassengersProperties,
  orderCompleted,
  Products,
  paymentInfoEntered,
  Variant,
  bundleSelected,
  productListFiltered,
  additionalServicePurchase,
  createOrderError,
  SearchTypeValueType,
} from '@web/utils/analytics/Avo';
import { createConsentedDestinations } from '@web/utils/analytics/rudderstackUtils';
import { getServiceFromServiceId } from '@web/utils/booking/bookingUtils';

export const pushToDataLayer = <T>(obj: T) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export const constructProductName = (itinerary: ItineraryFragment) =>
  `${itinerary.outbound[0]?.origin.code}>${
    itinerary.outbound[itinerary.outbound.length - 1]?.destination.code
  }${
    itinerary.homebound.length > 0
      ? `|${itinerary.homebound[0]?.origin.code}>${
          itinerary.homebound[itinerary.outbound.length - 1]?.destination.code
        }`
      : ''
  }`;

export const constructMbsProductName = (leg_summaries: LegGroupSummary[]) => {
  const legs = leg_summaries.flatMap((leg_summary) => leg_summary.legs);

  const [outbound, homebound] = partition((leg) => leg.is_outbound, legs);

  return `${outbound[0]?.origin.airport_iata}>${
    outbound[outbound.length - 1]?.destination.airport_iata
  }${
    homebound.length > 0
      ? `|${homebound[0]?.origin.airport_iata}>${
          homebound[homebound.length - 1]?.destination.airport_iata
        }`
      : ''
  }`;
};

export const pushCheapestOfferToDatalayer = ({
  cheapestOffer,
  currency,
}: {
  cheapestOffer?: OfferFragment | null;
  currency: CurrencyCode;
}) => {
  if (!cheapestOffer) {
    return;
  }

  pushToDataLayer({
    price: cheapestOffer.price,
    currency,
    outboundOriginAirportCode: cheapestOffer.itinerary.outbound[0]?.origin,
    outboundDestinationAirportCode:
      cheapestOffer.itinerary.outbound[
        cheapestOffer.itinerary.outbound.length - 1
      ]?.destination,
    fareClass: 'ECONOMY',
    searchDate: Date.now(),
    departureDate: toUTCLocaleString({
      date: dayjs(cheapestOffer.itinerary.outbound[0]?.departure).toDate(),
      locale: Language.English,
      options: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        hourCycle: 'h23',
      },
    }),

    returnDate:
      cheapestOffer.itinerary.homebound.length > 0
        ? toUTCLocaleString({
            date: dayjs(
              cheapestOffer.itinerary.homebound[0]?.departure,
            ).toDate(),
            locale: Language.English,
            options: {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              weekday: 'long',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              hourCycle: 'h23',
            },
          })
        : null,
    numberOfOutboundConnections: cheapestOffer.itinerary.outbound.length,
    numberOfHomeboundConnections: cheapestOffer.itinerary.homebound.length,
    outboundFlightDurationInSeconds:
      cheapestOffer.itinerary.outbound.reduce<number>(
        (acc, leg) => acc + leg.duration,
        0,
      ),
    homeboundFlightDurationInSeconds:
      cheapestOffer.itinerary.homebound.reduce<number>(
        (acc, leg) => acc + leg.duration,
        0,
      ),
    carriers: [
      ...cheapestOffer.itinerary.outbound,
      ...cheapestOffer.itinerary.homebound,
    ].map((leg) => ({
      iata: leg.operatingCarrier.code,
      number: leg.operatingCarrier.flightNumber,
    })),
    // We do not have price per outbound/return
    // Departure time & date are in the same value
  });
};

export const constructProductFromOffer = (
  offer: OfferFragment,
  currency: CurrencyCode,
  position = 1,
) => ({
  productId: 'travel',
  price: offer.price,
  currency,
  name: constructProductName(offer.itinerary),
  brand: [...offer.itinerary.outbound, ...offer.itinerary.homebound]
    .map((leg) => leg.marketingCarrier.code)
    .join('-'),
  category: null,
  position,
  url: offer.transferURL,
  variant:
    offer.itinerary.homebound.length === 0 ? Variant.ONEWAY : Variant.ROUNDTRIP,
});

const constructProductFromMbsOffer = (
  summary: Summary,
  currency: CurrencyCode,
) => [
  constructMbsProduct({
    legGroupSummary: summary.leg_summaries,
    price: summary.total.amount,
    currency,
  }),
  ...summary.leg_summaries.map((leg) => ({
    price: leg.bundle.price.amount,
    productId: 'bundle',
    brand: leg.bundle.code,
    name: leg.bundle.code,
    currency,
    category: null,
    variant: null,
    position: null,
    quantity: 1,
  })),
  ...summary.leg_summaries.reduce<Products[]>(
    (acc, leg) => [
      ...acc,
      ...leg.additional_services.map((additionalService) => ({
        price: additionalService.price.amount,
        productId: additionalService.service_class,
        brand: additionalService.service_class,
        name: additionalService.service_class,
        currency,
        category: null,
        variant: null,
        position: null,
        quantity: additionalService.quantity,
      })),
      ...leg.included_services.map((includedService) => ({
        price: 0,
        productId: includedService.service_class,
        brand: includedService.service_class,
        name: includedService.service_class,
        currency,
        category: null,
        variant: null,
        position: null,
        quantity: includedService.quantity,
      })),
    ],
    [],
  ),
];

export const sendProductClickedEvent = ({
  currency,
  journeyType = 'combined',
  offer,
  offerTags,
  position,
}: {
  currency: CurrencyCode;
  journeyType?: SearchTypeValueType;
  offer: OfferFragment;
  offerTags: string[];
  position: number;
}) =>
  productClicked({
    ...constructProductFromOffer(offer, currency, position),
    offerTags,
    searchType: journeyType,
  });

export const constructMbsProduct = ({
  currency,
  legGroupSummary,
  price,
}: {
  currency: CurrencyCode;
  legGroupSummary: LegGroupSummary[];
  price: number;
}) => ({
  productId: 'travel',
  price,
  currency,
  name: constructMbsProductName(legGroupSummary),
  brand: legGroupSummary
    .flatMap((legGroupSummary) =>
      legGroupSummary.legs.map((leg) => leg.marketing_carrier.code),
    )
    .join('-'),
  category: null,
  variant: legGroupSummary.every((legGroupSummary) =>
    legGroupSummary.legs.every((leg) => leg.is_outbound),
  )
    ? Variant.ONEWAY
    : Variant.ROUNDTRIP,
  position: 1,
  url: window.location.href,
  quantity: 1,
});

export const sendProductViewedEvent = ({
  bundleCode,
  currency,
  legGroupSummary,
  loadTime,
  offerId,
  price,
}: {
  bundleCode: string;
  currency: CurrencyCode;
  legGroupSummary: LegGroupSummary[];
  loadTime: string;
  offerId: string;
  price: number;
}) => {
  const mbsProduct = constructMbsProduct({ legGroupSummary, currency, price });
  pushToDataLayer({
    price,
    currency,
    orderId: offerId,
    bundleCode,
    brand: mbsProduct.brand,
  });

  productViewed({
    ...mbsProduct,
    loadTime,
  });
};

export const sendProductListViewedEvent = ({
  currency,
  journeyType = 'combined',
  loadTime,
  offers,
  totalAdult,
  totalChild,
  totalInfant,
}: {
  currency: CurrencyCode;
  journeyType?: SearchTypeValueType;
  loadTime: string;
  offers: OfferFragment[];
  totalAdult: number;
  totalChild: number;
  totalInfant: number;
}) =>
  productListViewed({
    loadTime,
    totalAdult,
    totalChild,
    totalInfant,
    products: offers.map((offer, index) => ({
      ...constructProductFromOffer(offer, currency),
      position: index + 1,
      quantity: 1,
    })),
    searchType: journeyType,
  });

export const sendNoSearchResultEvent = emptySearchResults;

export const sendPriceMismatchEvent = ({
  currency,
  isSwallow,
  newPrice,
  oldPrice,
}: {
  currency: CurrencyCode;
  isSwallow: boolean;
  newPrice: number;
  oldPrice: number;
}) => priceMismatch({ currency, oldPrice, newPrice, isSwallow });

export const sendAddServiceEvent = (
  serviceId: string,
  offer: OfferResponse,
  quantity: number,
) => {
  const service = getServiceFromServiceId(serviceId, offer.service_groups);

  if (service) {
    addService({
      quantity,
      productId: service.service_class,
      weight: 'weight' in service ? service.weight : undefined,
    });

    return;
  }

  const paxService = offer.all_pax_services.find(
    (paxService) => paxService.service_id === serviceId,
  );

  if (paxService) {
    addService({
      quantity: paxService.quantity,
      productId: paxService.service_class,
      weight: undefined,
    });
  }
};

export const sendServiceMismatchEvent = serviceMismatch;

export const sendUpdatePassengersEvent = (props: UpdatePassengersProperties) =>
  updatePassengers(props);

export const createRouteString = (route: RouteFragment[]) => {
  const stationsLegString = route
    .map((leg) => `${leg.origin.code},${leg.destination.code}`)
    .join(',');

  const departureArrivalString = route
    .map((leg) => `${leg.departure.toISOString()},${leg.arrival.toISOString()}`)
    .join(',');

  return `${stationsLegString},${departureArrivalString}`;
};

export const createLegString = (legs: Leg[]) => {
  const stationsLegString = legs
    .map((leg) => `${leg.origin.airport_iata},${leg.destination.airport_iata}`)
    .join(',');

  const departureArrivalString = legs
    .map((leg) => `${leg.departure},${leg.arrival}`)
    .join(',');

  return `${stationsLegString},${departureArrivalString}`;
};

export const sendOrderCompletedEvent = ({
  bookings,
  currency,
  dohopConnectName,
  itinerary,
  locale,
  orderId,
  partner,
  passengers,
  paymentMethod,
  summary,
}: {
  bookings: OrderSummaryResponseBookingsItem[];
  currency: CurrencyCode;
  dohopConnectName: string;
  itinerary: ItineraryWithLegCombo;
  locale: string;
  orderId: string;
  partner: Partner;
  passengers: Passenger[];
  paymentMethod?: string;
  summary: Summary;
}) => {
  const totalAdult = passengers.filter(
    (pax) => pax.expected_type === PaxType.a,
  ).length;

  const totalChild = passengers.filter(
    (pax) => pax.expected_type === PaxType.c,
  ).length;

  const totalInfant = passengers.filter(
    (pax) => pax.expected_type === PaxType.i,
  ).length;

  pushToDataLayer({
    totalAdult,
    totalChild,
    totalInfant,
    paymentMethod,
    variant:
      itinerary.homebound.length === 0 ? Variant.ONEWAY : Variant.ROUNDTRIP,
    legString: `${createLegString(itinerary.outbound)}${
      itinerary.homebound.length > 0
        ? `|${createLegString(itinerary.homebound)}`
        : ''
    }`,
    brand: [...itinerary.outbound, ...itinerary.homebound]
      .map((leg) => leg.marketing_carrier.code)
      .join('|'),
    price: summary.total.amount,
    currency,
    orderId,
  });

  orderCompleted({
    orderId,
    total: summary.total.amount,
    currency,
    totalAdult,
    totalChild,
    totalInfant,
    departureDate: toUTCLocaleString({
      date: dayjs(itinerary.outbound[0]?.departure).toDate(),
      locale: Language.English,
      options: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        hourCycle: 'h23',
      },
    }),
    returnDate:
      itinerary.homebound.length > 0
        ? toUTCLocaleString({
            date: dayjs(itinerary.homebound[0]?.departure).toDate(),
            locale: Language.English,
            options: {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              weekday: 'long',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              hourCycle: 'h23',
            },
          })
        : null,
    language: locale,
    paymentMethod: paymentMethod || 'unknown',
    partner,
    products: [
      ...bookings.flatMap((booking) =>
        booking.booking_type === 'travel'
          ? []
          : [
              {
                productId: booking.booking_type || 'service',
                price: booking.total.amount,
                currency: booking.total.currency,
                name: dohopConnectName,
                brand: dohopConnectName,
                category: null,
                variant: null,
                position: null,
                quantity: 1,
              },
            ],
      ),

      ...constructProductFromMbsOffer(summary, currency),
    ],
  });

  summary.leg_summaries.forEach((leg) =>
    leg.additional_services.forEach((additionalService) =>
      additionalServicePurchase({
        price: additionalService.price.amount,
        productId: additionalService.service_class,
        currency,
        quantity: additionalService.quantity,
      }),
    ),
  );
};

export const sendPaymentInfoEnteredEvent = ({
  checkoutId,
  currency,
  offer,
  orderId,
  paymentMethod,
  total,
}: {
  checkoutId: string;
  currency: CurrencyCode;
  offer: OfferResponse;
  orderId: string;
  paymentMethod: string;
  total: number;
}) =>
  paymentInfoEntered({
    checkoutId,
    orderId,
    paymentMethod,
    currency,
    total,
    products: constructProductFromMbsOffer(offer.summary, currency),
  });

export const sendSearchResultAnalyticsEvent = ({
  activeFilters,
  activeSort,
  bestOffers,
  currency,
  journeyType = 'combined',
  loadTime,
  offers,
  offersFilters,
  paxTypeAges,
}: {
  activeFilters: OfferFilters | null;
  activeSort: Sort;
  bestOffers?: { [key in Sort]: OfferFragment | null } | null;
  currency: CurrencyCode;
  journeyType?: SearchTypeValueType;
  loadTime: string;
  offers: OfferFragment[];
  offersFilters?: OfferFilters | null;
  paxTypeAges: {
    [paxType: string]: number[];
  };
}) => {
  if (offers.length === 0 || !offersFilters) {
    sendNoSearchResultEvent({ loadTime, searchType: journeyType });

    return;
  }

  pushCheapestOfferToDatalayer({
    currency,
    cheapestOffer: bestOffers?.CHEAPEST,
  });

  if (activeFilters === null || isDeepEqual(activeFilters, offersFilters)) {
    sendProductListViewedEvent({
      loadTime,
      currency,
      offers,
      totalAdult: paxTypeAges[CMSPassengerType.ADULT]?.length || 0,
      totalChild: paxTypeAges[CMSPassengerType.CHILD]?.length || 0,
      totalInfant: paxTypeAges[CMSPassengerType.INFANT]?.length || 0,
      journeyType,
    });

    return;
  }

  sendSearchResultFilterEvent({
    offers,
    currency,
    activeFilters: offersFilters,
    activeSort,
    journeyType,
  });
};

export const sendBundleSelectedEvent = ({
  bundleId,
  loadTime,
  offer,
}: {
  bundleId: string;
  loadTime: string;
  offer: OfferResponse;
}) => {
  const bundleName = offer.bundle_groups.reduce<string | undefined>(
    (name, bundleGroup) => {
      if (name) {
        return name;
      }

      return bundleGroup.bundles.find((bundle) => bundle.bundle_id === bundleId)
        ?.bundle_codes[0];
    },
    undefined,
  );

  if (bundleName) {
    bundleSelected({ bundleName, loadTime });
  }
};

export const addUserProperties = ({
  currency,
  experiments = [],
  locale,
  partner,
  residency,
}: {
  currency: CurrencyCode;
  experiments?: Experiment[];
  locale: Language;
  partner: Partner;
  residency: string;
}) => {
  return () =>
    window.rudderanalytics?.identify(
      {
        partner,
        originalReferrer: document.referrer,
        isMobile: 'ontouchstart' in document.documentElement,
        currency,
        residency,
        locale,
        ...experiments.reduce(
          (experimentUserProperties, experiment) => ({
            ...experimentUserProperties,
            [experiment.name]: experiment.variant,
          }),
          {},
        ),
      },
      createConsentedDestinations(),
    );
};

export const sendSearchResultFilterEvent = ({
  activeFilters,
  activeSort,
  currency,
  journeyType = 'combined',
  offers,
}: {
  activeFilters?: OfferFilters | null;
  activeSort: Sort;
  currency: CurrencyCode;
  journeyType?: SearchTypeValueType;
  offers: OfferFragment[];
}) => {
  productListFiltered({
    filters: activeFilters
      ? Object.keys(activeFilters).map((filter) => ({
          type: filter,
          value: activeFilters[filter as keyof typeof activeFilters] as string,
        }))
      : [],
    sorts: Object.values(Sort).map((sort) => ({
      type: sort as string,
      value: sort === activeSort ? 'true' : 'false',
    })),
    products: offers.map((offer, index) => ({
      ...constructProductFromOffer(offer, currency),
      position: index + 1,
      quantity: 1,
    })),
    searchType: journeyType,
  });
};

export const getCreateOrderErrorType = (error_code: string) =>
  error_code.split('.')[0] ?? '';

export const sendCreateOrderErrorEvent = ({
  error_code,
  isRecoverable,
}: {
  error_code: string;
  isRecoverable: boolean;
}) => {
  const error_type = getCreateOrderErrorType(error_code);

  createOrderError({
    errorType: error_code in ErrorCode ? error_type : 'unknown_error',
    isRecoverable,
  });
};
